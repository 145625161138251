
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import { renderUnit } from "@/utils/common";
import _ from "lodash";
import config from "@/views/user/order/order/compontent/detail-config";
import XLSX from "xlsx";

export default {
  components: {
    DeleteOutlined,
    DownloadOutlined,
  },
  emits: ["done", "update:visible"],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
  },
  data() {
    return {
      // 提交状态
      loading: false,
      // 弹窗标题
      title: "",
      // 订单信息
      order: {},
      voidLoading: false,
    };
  },
  computed: {
    packages() {
      return this.order.packages || [];
    },
    charges() {
      return this.order.charges || [];
    },
    logs() {
      return this.order.logs || [];
    },
    shipper_address() {
      return this.order.shipper_address || {};
    },
    order_shipper_address() {
      let str = this.shipper_address.address1 || "";
      if (this.shipper_address.address2) {
        str += " " + this.shipper_address.address2;
      }
      if (this.shipper_address.address3) {
        str += " " + this.shipper_address.address3;
      }

      return str;
    },
    address() {
      return this.order.address || {};
    },
    items() {
      return this.order.items || [];
    },
    /* 系统配置 */
    system_config() {
      return this.$store.state.system.config;
    },
    /* order_address */
    order_address() {
      let str = this.address.address1 || "";
      if (this.address.address2) {
        str += " " + this.address.address2;
      }

      if (this.address.address3) {
        str += " " + this.address.address3;
      }
      return str;
    },
    table_packages() {
      return [
        {
          key: "index",
          width: 48,
          align: "center",
          customRender: ({ index }) => index + 1,
        },
        {
          title: this.$t("尺寸"),
          dataIndex: "length",
          width: 120,
          ellipsis: true,
          customRender: ({ record }) =>
            `${record.length} x ${record.width} x ${
              record.height
            } ${this.renderUnit(record, "dims_unit")}`,
        },
        {
          title: this.$t("重量"),
          dataIndex: "weight",
          width: 100,
          customRender: ({ record }) => {
            return `${record.weight} ${this.renderUnit(record, "weight_unit")}`;
          },
        },
        {
          title: this.$t("Reference"),
          dataIndex: "reference1",
          slots: {
            customRender: "reference",
          },
        },
        /*{
                    title: this.$t('share.sku'),
                    dataIndex: 'sku',
                    align: 'center',
                    //width: 150
                },*/
        {
          title: this.$t("物品数量"),
          dataIndex: "pieces",
          width: 100,
        },
        {
          title: this.$t("费用"),
          dataIndex: "charge",
          width: 120,
          customRender: ({ record }) =>
            `${record.invoice_charge || record.charge} ${record.currency}`,
        },
        {
          title: this.$t("跟踪号"),
          dataIndex: "tracking_number",
          width: 200,
          slots: {
            customRender: "tracking_number",
          },
        },
      ];
    },
    table_charges() {
      return [
        {
          key: "index",
          width: 48,
          align: "center",
          customRender: ({ index }) => index + 1,
        },
        {
          title: this.$t("跟踪号"),
          dataIndex: "package_trakcing_number",
          width: 200,
          customRender: ({ record }) => {
            return (
              record.package_trakcing_number || record.order_tracking_number
            );
          },
        },
        {
          title: this.$t("费用名称"),
          dataIndex: "charge_name",
          width: 200,
        },
        {
          title: this.$t("备注"),
          dataIndex: "remark",
        },
        {
          title: this.$t("费用"),
          dataIndex: "charge",
          width: 120,
          customRender: ({ record }) =>
            `${[1, 3].includes(record.record_type) ? "+" : "-"}${
              record.charge
            } ${record.currency}`,
        },
        {
          title: this.$t("交易时间"),
          dataIndex: "created",
          width: 150,
        },
      ];
    },
  },
  watch: {
    data() {
      this.title = this.data.po_number;

      this.load_order();
    },
  },
  mounted() {
    this.data && this.load_order();
  },
  methods: {
    // 导出Excel-包裹
    exportPackageExcel() {
      const array = [
        [
          "尺寸",
          "重量",
          "Reference1",
          "Reference2",
          "物品数量",
          "费用",
          "跟踪号",
        ],
      ];
      this.packages.forEach((p) => {
        array.push([
          `${p.length} x ${p.width} x ${p.height} ${p.dims_unit}`,
          `${p.calc_weight} ${p.weight_unit}`,
          p.reference1,
          p.reference2,
          p.pieces,
          `${p.charge} ${p.currency}`,
          p.tracking_number,
        ]);
      });
      const sheet = XLSX.utils.json_to_sheet(array, {
        skipHeader: true,
      });

      sheet["!cols"] = [
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
      ];
      this.$util.exportSheet(XLSX, sheet, `${this.data.po_number}-包裹信息`);
    },
    load_order() {
      this.loading = true;

      this.$http
        .get(
          `/admin/correction_charges/get_order_info/${this.data.id}?get_more=1`
        )
        .then((res) => {
          this.loading = false;
          if (res.data.code === 0) {
            this.order = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$message.error(e.message);
        });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
    onClose() {
      this.updateVisible(false);
    },
    /* 取消订单 */
    voidOrder: _.debounce(function () {
      this.voidLoading = true;
      const hide = this.$message.loading("share.requesting", 0);

      this.$http
        .post("/admin/correction_charges/void", {
          ids: [this.data.id],
        })
        .then((res) => {
          hide();
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.load_order();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          hide();
          this.$message.error(e.message);
        })
        .finally(() => {
          this.voidLoading = false;
        });
    }, 300),
    /* 下载Label */
    downloadLabel(key) {
      const hide = this.$message.loading("share.requesting", 0);

      this.$http
        .post(`/admin/correction_charges/${key}`, {
          ids: [this.data.id],
        })
        .then((res) => {
          hide();
          if (res.data.code === 0) {
            const blob = this.base64ToBlob(res.data.data, "application/pdf");
            const fileName = `${this.data.po_number}.pdf`;

            if ("download" in document.createElement("a")) {
              // 非IE下载
              const el = document.createElement("a");

              el.download = fileName;
              el.style.display = "none";
              el.href = URL.createObjectURL(blob);
              document.body.appendChild(el);

              el.click();

              URL.revokeObjectURL(el.href);
              document.body.removeChild(el);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          } else {
            this.$message.error(`${res.data.msg}`);
          }
        })
        .catch((e) => {
          hide();
          this.$message.error(e.message);
        });
    },
    base64ToBlob(text, content_type) {
      var byteString = atob(text);
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var intArray = new Uint8Array(arrayBuffer);

      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      return new Blob([intArray], {
        type: content_type,
      });
    },
  },
  setup() {
    const { columns } = config(),
      table_items = columns;
    return {
      table_items,
      renderUnit,
    };
  },
};
